document.addEventListener('DOMContentLoaded', function () {


  // dropdown open and hide when click outside
  const dropdownLinks = document.querySelectorAll('.js-dropdown-link');
  const dropdownInnerElements = document.querySelectorAll('.js-dropdown');

  function dropdownClick(e) {
    const targetElement = document.getElementById(this.hash.substr(1));
    const isAlreadyActive = this.classList.contains('active');
    dropdownLinks.forEach(link => {
      link.classList.remove('active');
      link.setAttribute('aria-expanded', 'false');
    });
    dropdownInnerElements.forEach(el => el.classList.remove('open'));
    if (!isAlreadyActive) {
      dropdownLinks.forEach(link => {
        if (link.getAttribute('href') === this.hash) {
          link.classList.add('active');
          link.setAttribute('aria-expanded', 'true');
        }
      });
      targetElement.classList.add('open');
    }
    e.preventDefault();
  }

  function handleMouseUpTouchStartKeyUp(e) {
    const isInsideDropdown = Array.from(dropdownLinks)
      .concat(Array.from(dropdownInnerElements))
      .some(el => el.contains(e.target));
    if (!isInsideDropdown || e.keyCode === 27) {
      dropdownLinks.forEach(link => {
        link.classList.remove('active');
        link.setAttribute('aria-expanded', 'false');
      });
      dropdownInnerElements.forEach(el => el.classList.remove('open'));
    }
  }

  dropdownLinks.forEach(link => link.addEventListener('click', dropdownClick));
  document.addEventListener('mouseup', handleMouseUpTouchStartKeyUp);
  document.addEventListener('touchstart', handleMouseUpTouchStartKeyUp);
  document.addEventListener('keyup', handleMouseUpTouchStartKeyUp);


});
